import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecordWebcam } from "react-record-webcam";
import { uploadVideoToS3 } from "../utils/upload";

const Test = ({ onSubmit, step }) => {
  const [value, setValue] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const OPTIONS = {
    fileName: `question${step + 1}-video`,
    mimeType: "video/webm",
    width: 640,
    height: 480,
    // disableLogs: false,
  };
  const recordWebcam = useRecordWebcam(OPTIONS);

  const saveFile = async () => {
    const blob = await recordWebcam.getRecording();
    const getuserInfo = localStorage.getItem("user");
    const userInfo = await JSON.parse(getuserInfo);
    const userId = userInfo.userId;
    const key = `${userId}/question${step + 1}-video.webm`;
    await uploadVideoToS3(blob, key, setUploadStatus);
  };

  useEffect(() => {
    recordWebcam.status !== "OPEN" && recordWebcam.open();
  }, []);

  useEffect(() => {
    if (recordWebcam.status === "OPEN") {
      recordWebcam.start();
    }
  }, [recordWebcam]);

  const onNextQuestion = () => {
    setValue(null);

    recordWebcam.stop();
    setTimeout(async () => {
      await saveFile();
      // recordWebcam.download();
      onSubmit();
      recordWebcam.retake();
      const res = step === 8 ? await storeApi() : null;
      // console.log(step, res, videoFiles);
    }, 500);
  };

  const storeApi = async () => {
    const url = "https://masoud.us/examPost.php";

    const getuserInfo = localStorage.getItem("user");
    const userInfo = await JSON.parse(getuserInfo);
    console.log("userinfo", userInfo);

    const body = {
      userId: userInfo.userId,
      fullname: userInfo.name,
      phone: userInfo.tel,
      level: userInfo.level,
      major: userInfo.major,
      type: userInfo.type,
    };

    return fetch(url, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log("fetch error" + err);
      });
  };

  return (
    <div style={{ padding: "100px 20px" }}>
      {uploadStatus ? (
        <Typography
          variant="h4"
          component="h4"
          mb={2}
          sx={{ bgcolor: "#e1e1e1", p: 2, borderRadius: 4 }}
        >
          {uploadStatus}
        </Typography>
      ) : step === 9 ? (
        <Typography
          variant="h5"
          component="h5"
          mb={2}
          sx={{ bgcolor: "#e1e1e1", p: 2, borderRadius: 4 }}
        >
          با تشکر از همکاری صمیمانه شما ، این آزمون به پایان رسید. اکنون
          میتوانید صفحه را ببندید
        </Typography>
      ) : (
        <>
          <Typography variant="h4" component="h2" pb={2}>
            {TESTS[step].title}
          </Typography>
          <div
            style={{
              backgroundColor: "#e1e1e1",
              padding: 20,
              borderRadius: 10,
            }}
          >
            <Typography variant="h6" component="p" py={5}>
              {TESTS[step].description}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                // value={value}
                // onChange={(e) => setValue(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="گزینه ۱"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="گزینه ۲"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="گزینه ۳"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="گزینه ۴"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ padding: "20px 0" }}>
            <Button
              onClick={() => {
                onNextQuestion();
              }}
              size="large"
              variant="contained"
              // disabled={value ? false : true}
            >
              {step === 8 ? "پایان آزمون" : "سوال بعدی"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const TESTS = [
  {
    title: "سوال ۱ از ۹",
    description:
      " دستور العمل: به یک کتاب یا جزوه فیزیکی (کاغدی) نگاه کنید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۲ از ۹",
    description:
      "دستور العمل: با یک فرد(فرضی) در نزدیکی خود صحبت کنید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۳ از ۹",
    description:
      "دستور العمل: با استفاده از تلفن همراه خود با یک فرد(فرضی) از راه دور ارتباط برقرار کنید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۴ از ۹",
    description:
      "دستور العمل: از یک نفر بخواهید در کنار شما قرار بگیرد و تظاهر به مشارکت در آزمون کند و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۵ از ۹",
    description:
      "دستور العمل: به یک کتاب یا جزوه الکترونیکی(می تواند در موبایل یا لپ تاپ باشد) نگاه کنید و  سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۶ از ۹",
    description:
      "دستور العمل: یک صفحه جدید در مرورگر خود باز کنید و یک موضوع جست و جو کنید. دوباره به صفحه آزمون باز گردید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۷ از ۹",
    description:
      "دستور العمل: یک نرم افزار را در کامپیوتر خود باز کنید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۸ از ۹",
    description:
      "دستور العمل: از جای خود بلند شوید و یک فرد دیگر را در جای خود بنشانید . دوباره به صندلی خود برگردید و سپس یکی از گزینه های زیر را انتخاب کنید.",
  },
  {
    title: "سوال ۹ از ۹",
    description:
      "این جمله را بخوانید و و بدون انجام تخلف، یکی از گزینه های زیر را انتخاب کنید.",
  },
];

export default Test;
