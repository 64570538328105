import Test from "./components/Test";
import "./App.css";
import Vazir from "./fonts/Vazir.woff2";

import {
  AppBar,
  Container,
  CssBaseline,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import Intro from "./components/Intro";
import Recorder from "./components/Recorder";
import SignUp from "./components/SignUp";

const theme = createTheme({
  typography: {
    fontFamily: "Vazir, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Vazir';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Vazir'), local('Vazir'), url(${Vazir}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

function App() {
  const [step, setStep] = useState(0);
  const [screen, setScreen] = useState("intro");

  const onNext = async () => {
    setStep(step + 1);
  };

  const onSubmitIntro = () => {
    setScreen("signup");
  };

  const onRecorderSubmit = () => {
    setScreen("test");
  };

  const onSubmitSignUp = () => {
    setScreen("recorder");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="App">
        <AppBar>
          <Typography variant="h5" component={"h5"} p={2}>
            آزمون آنلاین
          </Typography>
        </AppBar>
        <Container style={{ backgroundColor: "#f1f1f1" }}>
          {screen === "intro" ? (
            <Intro onSubmit={onSubmitIntro} />
          ) : screen === "test" ? (
            <Test onSubmit={onNext} step={step} />
          ) : screen === "recorder" ? (
            <Recorder onSubmit={onRecorderSubmit} />
          ) : (
            screen === "signup" && <SignUp onSubmit={onSubmitSignUp} />
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
