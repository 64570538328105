import { useRecordWebcam } from "react-record-webcam";
import React, { useEffect } from "react";
import { Button } from "@mui/material";

const OPTIONS = {
  fileName: "test-filename",
  mimeType: "video/mp4",
  width: 340,
  height: 240,
  disableLogs: false,
};

const Recorder = ({ onSubmit }) => {
  const recordWebcam = useRecordWebcam(OPTIONS);
  const cameraStatus = recordWebcam.status;

  useEffect(() => {
    recordWebcam.status !== "OPEN" && recordWebcam.open();
  }, [recordWebcam]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#f1f1f1",
        height: "100vh",
      }}
    >
      {/* <button onClick={recordWebcam.open}>Open camera</button>
      <button onClick={recordWebcam.start}>Start recording</button>
      <button onClick={recordWebcam.stop}>Stop recording</button>
      <button onClick={recordWebcam.retake}>Retake recording</button>
      <button onClick={recordWebcam.download}>Download recording</button>
      <button onClick={saveFile}>Save file to server</button> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 340,
          height: 240,
          backgroundColor: "#000",
        }}
      >
        {cameraStatus && cameraStatus === "OPEN" ? (
          <video ref={recordWebcam.webcamRef} autoPlay muted />
        ) : null}
      </div>
      {cameraStatus !== "OPEN" ? <p> لطفا دوربین خود را فعال کنید</p> : null}
      <Button
        disabled={recordWebcam.status !== "OPEN" ? true : false}
        onClick={onSubmit}
        variant="contained"
        size="large"
        sx={{ mt: 2 }}
      >
        شروع آزمون
      </Button>
    </div>
  );
};

export default Recorder;
