import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

const SignUp = ({ onSubmit }) => {
  const [form, setForm] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    const userId = uuid();
    setForm({ ...form, userId });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    setBtnDisabled(
      !form.name || !form.tel || !form.level || !form.major || !form.type
    );
  }, [form]);

  const setData = () => {
    localStorage.setItem("user", JSON.stringify(form));
    onSubmit();
  };
  return (
    <div style={{ padding: "100px 20px" }}>
      <Typography variant="h5" component="h2" mb={2}>
        اطلاعات فردی
      </Typography>
      <div style={{ backgroundColor: "#e1e1e1", padding: 20, borderRadius: 4 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "20px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <p>نام و نام خانوادگی</p>
              <TextField
                id="field-1"
                name="name"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>شماره تلفن همراه</p>
              <TextField
                id="field-2"
                name="tel"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>مقطع</p>
              <TextField
                id="field-3"
                name="level"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>رشته تحصیلی</p>
              <TextField
                id="field-4"
                name="major"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>دوره</p>
              <TextField
                id="field-5"
                name="type"
                variant="outlined"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            disabled={btnDisabled}
            variant="contained"
            size="large"
            onClick={() => {
              setData();
            }}
          >
            ادامه
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
