import { Button, Typography } from "@mui/material";
import React from "react";

const Intro = ({ onSubmit }) => {
  return (
    <div style={{ padding: "100px 20px" }}>
      <Typography
        variant="h6"
        component="p"
        mb={2}
        sx={{ bgcolor: "#e1e1e1", p: 2, borderRadius: 4 }}
      >
        با سلام
        <br />
        ضمن تشکر از همکاری شما، این آزمون آنلاین چند گزینه ای جهت تکمیل فاز دوم
        پایان نامه کارشناسی ارشد اینجانب با عنوان "تاثیر استفاده از ابزار های
        نظارت مبتنی بر هوش مصنوعی در تشخیص تقلب در آزمون های آنلاین، مورد مطالعه
        آزمون های چند گزینه ای در ایران" طراحی شده است. این آزمون مجموعا شامل 9
        پرسش است و برای شرکت در این آزمون می بایست از لپ تاپ استفاده نمایید
      </Typography>
      <Typography
        variant="h6"
        component="p"
        mb={2}
        sx={{ bgcolor: "#e1e1e1", p: 2, borderRadius: 4 }}
      >
        لازم به ذکر است که در طول آزمون تصویر شما ضبط می شود. هدف از ضبط تصویر
        ویدیویی شما سنجش میزان تشخیص تقلب توسط ناظر انسانی و ابزار های مبتنی بر
        هوش مصنوعی می باشد. در این آزمون انحراف چشم و سر خارج از محدوده مانیتور،
        صحبت کردن ، وجود دونفر در تصویر و مغایرت هویت، تخلف محسوب می شود در
        ابتدای هر سوال دستورالعمل رفتاری برای پاسخ گویی قرار داده شده است و از
        شما خواسته می شود تا به طور عمدی رفتار متقلبانه انجام دهید
      </Typography>
      <Typography
        variant="h6"
        component="p"
        mb={2}
        sx={{ bgcolor: "#e1e1e1", p: 2, borderRadius: 4 }}
      >
        تصاویر و اطلاعات شخصی شما صرفا جهت انجام پژوهش حاضر استفاده خواهد شد و
        در اختیار هیچ فرد، گروه یا سازمانی قرار داده نخواهد شد.
      </Typography>
      <Typography variant="h6" component="p" mb={2} align="center">
        با سپاس
        <br />
        فاطمه نیکنام
        <br />
        دانشجوی کارشناسی ارشد مدیریت فناوری اطلاعات
        <br />
        دانشگاه تربیت مدرس
      </Typography>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          مطالعه کردم و موافقم
        </Button>
      </div>
    </div>
  );
};

export default Intro;
