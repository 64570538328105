import AWS from "aws-sdk";

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;

const s3 = new AWS.S3({
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: region,
});

export const uploadVideoToS3 = (blob, key, setUploadStatus) => {
  const params = {
    Bucket: "online-exam-files",
    Key: key,
    Body: blob,
    // ACL: 'public-read',
  };

  let uploadedBytes = 0;
  let totalBytes = 0;
  let startTime = Date.now();

  const managedUpload = s3.upload(params);

  managedUpload.on("httpUploadProgress", (progress) => {
    uploadedBytes = progress.loaded;
    totalBytes = progress.total;
    const elapsedTime = Date.now() - startTime;
    const uploadSpeed = (((uploadedBytes / elapsedTime) * 1000) / 1024).toFixed(
      2
    );
    const uploadedKB = (uploadedBytes / 1024).toFixed(2);
    const totalKB = (totalBytes / 1024).toFixed(2);
    const uploadPercentage = ((uploadedBytes / totalBytes) * 100).toFixed(2);
    uploadedBytes &&
      setUploadStatus(
        `در حال بارگذاری تصویر لطفا صبر کنید ${uploadPercentage}%`
      );
    console.log(
      `Uploading ${uploadPercentage}% (${uploadedKB} KB/${totalKB} KB) at ${uploadSpeed} KB/s`
    );
  });

  return new Promise((resolve, reject) => {
    managedUpload.send((err, data) => {
      if (err) {
        console.error("Error uploading video to S3:", err);
        setUploadStatus("خطا در بارگذاری تصویر لطفا دوباره آزمون را شروع کنید");

        reject(err);
      } else {
        setUploadStatus("بارگذاری موفقیت آمیز");
        setTimeout(() => {
          setUploadStatus("");
        }, 1000);
        console.log("Upload successful:", data);
        resolve(data);
      }
    });
  });
};

/* usage example:
  const blob = new Blob([webmVideoData], { type: 'video/webm' });
  const key = 'my-webm-video.webm';

  uploadVideoToS3(blob, key);
*/
